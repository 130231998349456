<template>
  <el-dialog
    :title="editPopupData.title"
    class="popUp"
    width="1200px"
    top="2%"
    :visible.sync="editPopupDialogVisible"
    :before-close="editPopup"
  >
    <!-- <editPopup :editPopupData="editPopupData" /> -->
    <div class="editPopup">
      <el-container>
        <el-aside>
          <div class="shell" v-for="(item, index) in data1" :key="index">
            <span class="p1">{{ index + 1 }}.</span>
            <el-input
              size="small"
              v-model="item.docName"
              placeholder="请输入文件名称"
              @blur="determine(item.docName)"
              v-if="item.docId == pageDocId1"
              maxlength="50"
            ></el-input>
            <span
              v-else
              class="p2"
              :style="!editPopupData.btnShow ? 'width:100%' : ''"
              @click="seeInfo(item)"
              :class="item.docId == pageDocId2 ? 'tsColor1' : ''"
              >{{ item.docName }}</span
            >
            <span
              v-show="editPopupData.btnShow"
              class="p3"
              @click="rename(item.docId)"
              >重命名</span
            >
            <span
              v-show="editPopupData.btnShow"
              class="p4"
              @click="deleteFile(item.docId)"
              >删除</span
            >
          </div>
        </el-aside>
        <el-container>
          <el-header
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
            v-if="editPopupData.btnShow"
          >
            <span v-show="editPopupData.btnShow">仅支持上传pdf格式的文件</span>
            <el-upload
              v-show="editPopupData.btnShow"
              class="upload-demo"
              action
              multiple
              accept=".pdf"
              :show-file-list="false"
              :http-request="otherUpload"
              ><el-button class="bgc-bv" style="margin: 0 10px" size="small"
                >上传文件</el-button
              >
            </el-upload>
            <el-button
              v-show="editPopupData.btnShow"
              class="bgc-bv"
              style="margin: 0 10px"
              size="small"
              @click="downloadFileZip"
              >下载</el-button
            >
          </el-header>
          <el-main
            ><div
              id="pdf-content"
              style="height: 600px"
              v-show="data1.length > 0"
            ></div>
            <div
              style="position: relative; width: 100%; height: 100%"
              v-show="!data1.length"
            >
              <Empty slot="empty" />
            </div>
          </el-main>
        </el-container>
      </el-container>
    </div>
  </el-dialog>
  <!-- 模板 - 一企一档 - 编辑时的弹出框 -->
</template>

<script>
import { mapGetters } from "vuex";
import { acceptTypeImg } from "@/utils/common";
import Empty from "@/components/Empty2.vue";
import pdf from "pdfobject";
export default {
  name: "editPopup",
  props: ["editPopupData"],
  components: {
    Empty,
  },
  data() {
    return {
      // 左侧列表数据
      data1: [],
      // 重命名时的左侧列表数据
      pageDocId1: null,
      // 查看时的左侧列表数据
      pageDocId2: null,
      // 判断接口 - 根据editPopupData中传的docType类型判断：列表/上传/下载的接口
      publicUrl: {
        listUrl: "", // 列表
        uploadUrl: "", // 上传
        downloadUrl: "", // 下载
      },
      editPopupDialogVisible: false,
    };
  },
  // watch: {
  //   editPopupData: {
  //     //深度监听，可监听到对象、数组的变化
  //     handler(val, oldVal) {
  //       // 200-安全培训教育制度；

  //     },
  //     immediate: true,
  //     deep: true, //true 深度监听
  //   },
  // },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    showPopUp(res) {
      ;
      this.editPopupDialogVisible = true;
      if (res.docType == "200") {
        this.publicUrl.listUrl = "/dm/enterprise/record/institution/list";
        this.publicUrl.uploadUrl =
          "/dm/enterprise/record/institution/upload/batch";
        this.publicUrl.downloadUrl =
          "/dm/enterprise/record/institution/download";
        // 205-关于任命安全教育主管部门和人员的文件；
      } else if (res.docType == "205") {
        this.publicUrl.listUrl = "/dm/enterprise/record/appoint/list";
        this.publicUrl.uploadUrl = "/dm/enterprise/record/appoint/upload/batch";
        this.publicUrl.downloadUrl = "/dm/enterprise/record/appoint/download";
        // 215-培训需求调查表；
      } else if (res.docType == "215") {
        this.publicUrl.listUrl = "/dm/enterprise/record/survey/list";
        this.publicUrl.uploadUrl = "/dm/enterprise/record/survey/upload/batch";
        this.publicUrl.downloadUrl = "/dm/enterprise/record/survey/download";
        // 240-培训条件证明材料
      } else if (res.docType == "240") {
        this.publicUrl.listUrl = "/dm/enterprise/record/demonstrate/list";
        this.publicUrl.uploadUrl =
          "/dm/enterprise/record/demonstrate/upload/batch";
        this.publicUrl.downloadUrl =
          "/dm/enterprise/record/demonstrate/download";
      }
      this.getEditPopupData(res);
      // this.playload = JSON.parse(res);
      // this.queryData();
    },
    // 重命名
    rename(id) {
      this.pageDocId1 = id;
    },
    // 确定重命名
    determine(docName) {
      let str = docName.replace(/\s*/g, "");
      if (str.length < 3) {
        this.$message({
          type: "warning",
          message: "文件名称最少为三个字!",
        });
      } else {
        this.$post("/sys/document/rename", {
          docId: this.pageDocId1,
          docName: str,
        }).then((res) => {
          this.pageDocId1 = null;
        });
      }
    },
    // 删除
    deleteFile(docId) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.$post("/sys/document/delete", {
            docId,
          }).then((res) => {
            this.getEditPopupData(this.editPopupData);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取左侧列表
    getEditPopupData(val) {
      this.$post(this.publicUrl.listUrl, val).then((res) => {
        this.data1 = res.data;
        var options = {
          pdfOpenParams: {
            pagemode: "thumbs",
            toolbar: 0,
            navpanes: 0,
            statesbar: 0,
            view: "FitV",
            scrollbars: "0",
          },
        };
        if (res.data.length) {
          this.pageDocId2 = res.data[0].docId;
          pdf.embed(res.data[0].fileKey, "#pdf-content", options);
        }
      });
    },
    // 点击左侧列表 - 查看
    seeInfo(info) {
      console.log(info);
      this.pageDocId2 = info.docId;
      var options = {
        pdfOpenParams: {
          pagemode: "thumbs",
          toolbar: 0,
          navpanes: 0,
          statesbar: 0,
          view: "FitV",
          scrollbars: "0",
        },
      };
      pdf.embed(info.fileKey, "#pdf-content", options);
    },
    // 下载
    downloadFileZip() {
      this.$post(this.publicUrl.downloadUrl, this.editPopupData).then((res) => {
        // window.location.href = res.data;
        if (res.status == "0") {
          let list = res.data;
          this.editPopupDialogVisible = false;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 一企一档 - 编辑中上传文件
    otherUpload(res) {
      // for (let i = 0; i < this.data1.length; i++) {
      //   if(this.data1[i].docName == res.file.name){
      //     this.$message.warning(res.file.name + "文件已存在!");
      //     return false;
      //   }
      // }
      if (acceptTypeImg(res)) {
        this.$message.error("上传文件的大小不能超过 10MB!");
        return false;
      } else {
        let formData = new FormData();
        let fileNmae = res.file.name; // 文件名
        let extension = fileNmae.replace(/.+\./, ""); // 文件类型
        if (!".pdf,.PDF".includes(extension[extension.length - 1])) {
          this.$message.warning({
            message: "只能上传pdf格式的文件！",
            duration: 1000,
          });
          return false;
        }
        formData.append("folder ", "QUESTION");
        formData.append("file ", res.file);
        formData.append("fileType ", extension);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.$post(this.publicUrl.uploadUrl, [
              {
                compId: this.editPopupData.compId,
                docName: fileNmae,
                enterpriseId: this.editPopupData.enterpriseId,
                fileKey: result.data.fileKey,
                fileSize: res.file.size,
                fileType: extension,
                year: this.editPopupData.year,
              },
            ]).then((resz) => {
              this.getEditPopupData(this.editPopupData);
            });
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="less" scoped>
.editPopup {
  height: 100%;
  .el-container {
    .el-aside {
      width: 340px !important;
      padding: 20px;
      line-height: 20px;
      height: 650px;
      //   border-right: 1px solid #1b2e4b;
      .shell {
        // line-height: 45px;
        // overflow: hidden;
        display: flex;
        margin-top: 0.5rem;
        /* justify-content: center; */
        // align-items: center;
        .p1 {
          width: 30px;
        }
        .p2 {
          width: 140px;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
          margin-right: 30px;
          &:hover {
            cursor: pointer;
            color: #409eff;
          }
        }
        .p3,
        .p4 {
          width: 50px;
          text-align: center;
          color: #409eff;
          cursor: pointer;
        }
        .el-input {
          width: 160px;
        }
      }
    }
    .el-container {
      .el-header {
        height: 44px !important;
        text-align: right;
        padding-top: 10px;
        .el-button {
          margin-right: 10px;
        }
        .upload-demo {
          .el-button {
            margin-right: 0;
          }
        }
      }
      .el-main {
        height: 100%;
        padding-top: 10px;
      }
    }
  }
}
.tsColor1 {
  color: #409eff !important;
}
.editPopup .el-container .el-header /deep/ .upload-demo .el-upload {
  border: 0;
  max-width: 100px !important;
  height: 32px !important;
  line-height: 32px;
  float: right;
}
</style>
